define('ember-cli-semantic-ui/components/ui-dropdown', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-dropdown', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, layout, SemanticSettings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], {
    layout: layout['default'],
    classNames: ['ui', 'dropdown'],
    moduleName: 'dropdown',
    didInsertElement: function didInsertElement() {
      var component = this;
      var selected = this.get('selected');
      this.setSemanticConfiguration({
        onChange: function onChange(value) {
          component.set('selected', value);
        },
        fullTextSearch: this.get('fullTextSearch') || false
      });
      this.$().dropdown('set selected', selected);
      this._super();
    }
  });

});