define('ember-cli-semantic-ui/components/ui-popup', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-popup', 'ember-cli-semantic-ui/mixins/option-collect'], function (exports, Ember, layout, OptionCollect) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(OptionCollect['default'], {
    tagName: 'span',
    layout: layout['default'],
    availableOptions: ['content', 'title', 'popup', 'position', 'transition', 'hoverable'],
    didInsertElement: function didInsertElement() {
      var popupOptions = this.collectOptions();

      this.$().popup(popupOptions);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().popup('destroy');
      this._super();
    }
  });

});