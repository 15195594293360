define('ember-cli-semantic-ui/components/ui-shape', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-shape', 'ember-cli-semantic-ui/mixins/option-collect'], function (exports, Ember, layout, OptionCollect) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(OptionCollect['default'], {
    layout: layout['default'],
    availableOptions: ['duration'],
    didInsertElement: function didInsertElement() {
      var shapeElement = this.$('.ui.shape');
      var options = this.collectOptions();

      shapeElement.shape(options);
      this.set('shapeElement', shapeElement);
    },
    actions: {
      changeSide: function changeSide() {
        var transitionType = this.get('transitionType') || 'flip up';
        Ember['default'].$(this.get('shapeElement')).shape(transitionType);
      }
    }
  });

});