define('ember-cli-data-validation/pattern-validator', ['exports', 'ember', 'ember-cli-data-validation/validator', 'ember-cli-data-validation/utils'], function (exports, Ember, Validator, utils) {

	'use strict';

	exports['default'] = Validator['default'].extend({

		/**
	  * RegExp like pattern that would be used to test
	  * the Attribute value.
	  *
	  * @property pattern
	  * @type {RegExp}
	  * @default null
	  */
		pattern: null,

		validate: function validate(name, value /*, attribute, model*/) {
			var pattern = this.get('pattern');

			Ember['default'].assert('You must define a RegExp pattern in order to validate.', pattern instanceof RegExp);

			if (utils.hasValue(value) && !value.toString().match(pattern)) {
				return this.format();
			}
		}
	});

});