define('ember-cli-data-validation/initializers/ember-cli-data-validation', ['exports', 'ember-cli-data-validation/message-resolver'], function (exports, DefaultMessageResolver) {

  'use strict';

  exports.initialize = initialize;

  function initialize(container, application) {
    application.register('ember-cli-data-validation@resolver:validation-message', DefaultMessageResolver['default']);
  }

  exports['default'] = {
    name: 'data-validation',
    initialize: initialize
  };

});