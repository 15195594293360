define('ember-cli-semantic-ui/mixins/option-collect', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    init: function init() {
      Ember['default'].assert('You must set an availableOptions arrary to use this mixin', this.get('availableOptions'));
      this._super();
    },
    collectOptions: function collectOptions() {
      var _this = this;

      return this.get('availableOptions').reduce(function (options, option) {
        if (_this.get(option)) {
          options[option] = _this.get(option);
          return options;
        }
        return options;
      }, {});
    }
  });

});