define('ember-notify/components/ember-notify', ['exports', 'ember', 'ember-notify/templates/components/ember-notify', 'ember-notify/message'], function (exports, Ember, layout, Message) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],

    notify: Ember['default'].inject.service(),
    source: Ember['default'].computed.oneWay('notify'),
    messages: null,
    closeAfter: 2500,

    classNames: ['ember-notify-cn'],
    messageStyle: 'foundation',

    init: function init() {
      this._super();
      this.set('messages', Ember['default'].A());
      this.get('source').setTarget(this);

      var style = this.get('messageStyle'),
          theme;
      switch (style) {
        case 'foundation':
          theme = FoundationTheme.create();
          break;
        case 'bootstrap':
          theme = BootstrapTheme.create();
          break;
        case 'refills':
          theme = RefillsTheme.create();
          break;
        default:
          throw new Error('Unknown messageStyle ' + style + ': options are \'foundation\' and \'bootstrap\'');
      }
      this.set('theme', theme);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('source').setTarget(null);
    },
    show: function show(message) {
      if (this.get('isDestroyed')) return;
      if (!(message instanceof Message['default'])) {
        message = Message['default'].create(message);
      }
      this.get('messages').pushObject(message);
      return message;
    }
  });

  var Theme = Ember['default'].Object.extend({
    classNamesFor: function classNamesFor(message) {
      return message.get('type');
    }
  });

  var FoundationTheme = Theme.extend({
    classNamesFor: function classNamesFor(message) {
      var type = message.get('type');
      var classNames = ['alert-box', type];
      if (type === 'error') classNames.push('alert');
      return classNames.join(' ');
    }
  });

  var BootstrapTheme = Theme.extend({
    classNamesFor: function classNamesFor(message) {
      var type = message.get('type');
      if (type === 'alert' || type === 'error') type = 'danger';
      var classNames = ['alert', 'alert-' + type];
      return classNames.join(' ');
    }
  });

  var RefillsTheme = Theme.extend({
    classNamesFor: function classNamesFor(message) {
      var type = message.get('type');
      var typeMapping = {
        success: 'success',
        alert: 'error',
        error: 'error',
        info: 'notice',
        warning: 'alert'
      };
      return 'flash-' + typeMapping[type];
    }
  });

  exports.Theme = Theme;
  exports.FoundationTheme = FoundationTheme;
  exports.BootstrapTheme = BootstrapTheme;
  exports.RefillsTheme = RefillsTheme;

});