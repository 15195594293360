define('ember-cli-data-validation/messages', ['exports'], function (exports) {

	'use strict';

	exports['default'] = {
		error: 'Some required information is missing or incomplete. Please correct your entries and try again.',
		acceptance: '%@ must be checked',
		digit: '%@ must be a digit',
		number: '%@ must be a number',
		email: '%@ is an invalid email address',
		url: '%@ is an invalid URL',
		required: '%@ is required',
		max: {
			string: '%@ must be at most %@ characters',
			number: '%@ must be less than or equal to %@'
		},
		min: {
			string: '%@ must be at least %@ characters',
			number: '%@ must be greater than or equal to %@'
		},
		range: {
			string: '%@ must be between %@ and %@ characters',
			number: '%@ must be between %@ and %@'
		},
		pattern: '%@ is invalid',

		test: {
			name: {
				range: 'Tesint tange',
				min: 'MINNNNNN'
			}
		}
	};

});