define('ember-cli-semantic-ui/components/ui-dimmable', ['exports', 'ember', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, SemanticSettings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], {
    classNames: ['ui', 'dimmable'],
    moduleName: 'dimmer',
    click: function click() {
      if (this.get('targetElement')) {
        return this.$(this.get('targetElement')).dimmer('toggle');
      }
      this.$().dimmer('toggle');
    }
  });

});