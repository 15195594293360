define('ember-cli-semantic-ui/components/ui-modal', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-modal'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    moduleName: 'modal',
    didInsertElement: function didInsertElement() {
      var modalElement = this.$('.ui.modal');

      var triggerElement = this.get('triggerElement') || '.ui.button';

      this.$(triggerElement).on('click', function () {
        modalElement.modal('toggle');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$()[this.get('moduleName')]('destroy');
      this._super();
    }
  });

});