define('ember-cli-data-validation/validators/range', ['exports', 'ember', 'ember-cli-data-validation/validator'], function (exports, Ember, Validator) {

	'use strict';

	exports['default'] = Validator['default'].extend({
		/**
	  * Number representing the starting point
	  * of the range validation.
	  *
	  * @property from
	  * @type {Number}
	  * @default null
	  */
		from: null,

		/**
	  * Number representing the ending point
	  * of the range validation.
	  *
	  * @property to
	  * @type {Number}
	  * @default null
	  */
		to: null,

		validate: function validate(name, value, attribute) {
			var type = attribute.type,
			    fromValue = this.get('from'),
			    toValue = this.get('to');

			Ember['default'].assert('You must define a `from` for RangeValidator', Ember['default'].isPresent(fromValue));
			Ember['default'].assert('You must define a `to` for RangeValidator', Ember['default'].isPresent(toValue));

			var validatorName = 'validate' + Ember['default'].String.classify(type);

			var invalid = true;

			if (Ember['default'].canInvoke(this, validatorName)) {
				invalid = Ember['default'].run(this, validatorName, value, fromValue, toValue);
			}

			if (invalid) {
				return this.format(fromValue, toValue);
			}
		},

		validateString: function validateString(value, fromValue, toValue) {
			if (typeof value !== 'string') {
				return true;
			}

			var length = value && value.length || 0;

			return length < fromValue || length > toValue;
		},

		validateNumber: function validateNumber(value, fromValue, toValue) {
			value = parseInt(value, 10);

			if (isNaN(value)) {
				return true;
			}

			return value < fromValue || value > toValue;
		}
	});

});