define('ember-cli-semantic-ui/components/ui-radio-collection', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-radio-collection'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    options: Ember['default'].computed('optionCollection', function () {
      var optionCollection = this.get('optionCollection');
      var label = this.get('label');
      var value = this.get('value');
      return optionCollection.map(function (item) {
        return {
          value: item[value],
          label: item[label],
          id: Ember['default'].generateGuid()
        };
      });
    }),
    collectionName: 'radioOptions',
    actions: {
      checkOption: function checkOption(option) {
        this.set('checked', option);
      }
    }
  });

});