define('ember-cli-data-validation/validators/acceptance', ['exports', 'ember-cli-data-validation/validator', 'ember-cli-data-validation/utils'], function (exports, Validator, utils) {

  'use strict';

  exports['default'] = Validator['default'].extend({
    validate: function validate(name, value) {
      if (value !== 'true' && (!utils.isBoolean(value) || value === false)) {
        return this.format();
      }
    }
  });

});