define('ember-cli-data-validation/utils', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.hasValue = hasValue;
  exports.isBoolean = isBoolean;

  function hasValue(value) {
    return Ember['default'].isPresent(value) || !Ember['default'].isEmpty(value);
  }

  /**
   * Determines if the value is Boolean.
   *
   * @param  {*}  obj
   * @return {Boolean}
   */

  function isBoolean(obj) {
    return obj === true || obj === false || Object.prototype.toString.call(obj) === '[object Boolean]';
  }

});