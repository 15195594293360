define('ember-cli-semantic-ui/components/ui-progress', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-progress', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, layout, SemanticSettings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], {
    layout: layout['default'],
    classNames: ['ui', 'progress'],
    moduleName: 'progress',
    updateProgress: Ember['default'].observer('progress', function () {
      this.$().progress({
        percent: this.get('progress')
      });
    })
  });

});