define('ember-cli-semantic-ui/components/ui-sticky', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-sticky', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, layout, SemanticSettings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], {
    layout: layout['default'],
    classNames: ['ui', 'sticky'],
    moduleName: 'sticky',
    didInsertElement: function didInsertElement() {
      if (this.get('targetElement')) {
        this.setSemanticConfiguration({
          context: this.get('targetElement')
        });
      }
      this._super();
    }
  });

});