define('ember-cli-daterangepicker/components/date-range-picker', ['exports', 'ember', 'moment'], function (exports, Ember, moment) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    classNames: ['form-group'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: undefined,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',

    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    rangeText: Ember['default'].computed('start', 'end', function () {
      var format = this.get('format');
      var serverFormat = this.get('serverFormat');
      var start = this.get('start');
      var end = this.get('end');
      if (!Ember['default'].isEmpty(start) && !Ember['default'].isEmpty(end)) {
        return moment['default'](start, serverFormat).format(format) + this.get('separator') + moment['default'](end, serverFormat).format(format);
      }
      return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    ranges: {
      'Today': [moment['default'](), moment['default']()],
      'Yesterday': [moment['default']().subtract(1, 'days'), moment['default']().subtract(1, 'days')],
      'Last 7 Days': [moment['default']().subtract(6, 'days'), moment['default']()],
      'Last 30 Days': [moment['default']().subtract(29, 'days'), moment['default']()],
      'This Month': [moment['default']().startOf('month'), moment['default']().endOf('month')],
      'Last Month': [moment['default']().subtract(1, 'month').startOf('month'), moment['default']().subtract(1, 'month').endOf('month')]
    },
    removeDropdownOnDestroy: false,
    cancelLabel: 'Cancel',
    applyAction: null,
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    firstDay: 0,

    //Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      var self = this;

      var momentStartDate = moment['default'](this.get('start'), this.get('serverFormat'));
      var momentEndDate = moment['default'](this.get('end'), this.get('serverFormat'));
      var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      var endDate = momentEndDate.isValid() ? momentEndDate : undefined;

      var momentMinDate = moment['default'](this.get('minDate'), this.get('serverFormat'));
      var momentMaxDate = moment['default'](this.get('maxDate'), this.get('serverFormat'));
      var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

      var options = {
        alwaysShowCalendars: this.get('alwaysShowCalendars'),
        autoUpdateInput: this.get('autoUpdateInput'),
        autoApply: this.get('autoApply'),
        locale: {
          cancelLabel: this.get('cancelLabel'),
          format: this.get('format'),
          firstDay: this.get('firstDay')
        },
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate,
        timePicker: this.get('timePicker'),
        buttonClasses: this.get('buttonClasses'),
        applyClass: this.get('applyClass'),
        cancelClass: this.get('cancelClass'),
        separator: this.get('separator'),
        singleDatePicker: this.get('singleDatePicker'),
        drops: this.get('drops'),
        opens: this.get('opens'),
        timePicker24Hour: this.get('timePicker24Hour'),
        timePickerSeconds: this.get('timePickerSeconds'),
        timePickerIncrement: this.get('timePickerIncrement'),
        showWeekNumbers: this.get('showWeekNumbers'),
        showDropdowns: this.get('showDropdowns'),
        linkedCalendars: this.get('linkedCalendars'),
        dateLimit: this.get('dateLimit'),
        parentEl: this.get('parentEl')
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }

      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.$('.daterangepicker-input').daterangepicker(options);
      });

      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        var start = picker.startDate.format(self.get('serverFormat'));
        var end = picker.endDate.format(self.get('serverFormat'));
        var applyAction = self.get('applyAction');

        if (applyAction) {
          Ember['default'].assert('applyAction for date-range-picker must be a function', typeof applyAction === 'function');
          applyAction(start, end);
        } else {
          self.setProperties({
            start: start, end: end
          });
        }
      });

      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        var cancelAction = self.get('cancelAction');

        if (cancelAction) {
          Ember['default'].assert('cancelAction for date-range-picker must be a function', typeof cancelAction === 'function');
          cancelAction();
        } else {
          self.set('start', self.get('start'));
          self.set('end', self.get('end'));
        }
      });
    },

    //Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      if (this.get('removeDropdownOnDestroy')) {
        Ember['default'].$('.daterangepicker').remove();
      }
    }
  });

});