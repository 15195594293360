define('ember-cli-semantic-ui/components/ui-radio-button', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],

    htmlChecked: Ember['default'].computed('value', 'checked', function () {
      return this.get('value') === this.get('checked');
    }),

    change: function change() {
      this.set('checked', this.get('value'));
    }
  });

});