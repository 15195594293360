define('ember-cli-data-validation/validators/min', ['exports', 'ember', 'ember-cli-data-validation/validator'], function (exports, Ember, Validator) {

	'use strict';

	exports['default'] = Validator['default'].extend({
		/**
	  * Min value for the validator.
	  *
	  * @property min
	  * @type {Number}
	  * @default null
	  */
		min: null,

		validate: function validate(name, value, attribute) {
			var type = attribute.type,
			    minValue = this.get('min');

			Ember['default'].assert('You must define a `min` for MinValidator', Ember['default'].isPresent(minValue));

			var validatorName = 'validate' + Ember['default'].String.classify(type);

			var invalid = true;

			if (Ember['default'].canInvoke(this, validatorName)) {
				invalid = Ember['default'].run(this, validatorName, value, minValue);
			}

			if (invalid) {
				return this.format(minValue);
			}
		},

		validateString: function validateString(value, min) {
			if (typeof value !== 'string') {
				return true;
			}

			var length = value && value.length || 0;

			return length < min;
		},

		validateNumber: function validateNumber(value, min) {
			value = parseInt(value, 10);

			if (isNaN(value)) {
				return true;
			}

			return value < min;
		}
	});

});