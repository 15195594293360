define('ember-cli-semantic-ui/templates/components/ui-checkbox', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@2.0.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "modules/ember-cli-semantic-ui/templates/components/ui-checkbox.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1,"type","checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) { dom.repairClonedNode(element0,[],true); }
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'name');
        morphs[2] = dom.createAttrMorph(element0, 'onchange');
        morphs[3] = dom.createAttrMorph(element0, 'checked');
        morphs[4] = dom.createAttrMorph(element1, 'for');
        morphs[5] = dom.createMorphAt(element1,0,0);
        return morphs;
      },
      statements: [
        ["attribute","id",["get","inputId",["loc",[null,[1,12],[1,19]]]]],
        ["attribute","name",["get","name",["loc",[null,[1,45],[1,49]]]]],
        ["attribute","onchange",["subexpr","action",["check"],[],["loc",[null,[1,61],[1,79]]]]],
        ["attribute","checked",["get","isChecked",["loc",[null,[1,90],[1,99]]]]],
        ["attribute","for",["concat",[["get","inputId",["loc",[null,[2,14],[2,21]]]]]]],
        ["content","label",["loc",[null,[2,25],[2,34]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});