define('ember-cli-semantic-ui/mixins/semantic-settings', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    moduleName: null,
    init: function init() {
      this._super();
      Ember['default'].assert('You must set a moduleName string to use this mixin', this.get('moduleName'));
    },
    didInsertElement: function didInsertElement() {
      var moduleName = this.get('moduleName');
      var semanticConfiguration = this.get('semanticConfiguration');
      if (Ember['default'].$.isPlainObject(semanticConfiguration)) {
        this.$()[moduleName](semanticConfiguration);
      } else {
        this.$()[moduleName]();
      }
      this._super();
    },
    setSemanticConfiguration: function setSemanticConfiguration(settings) {
      this.set('semanticConfiguration', Ember['default'].$.merge(settings, this.getWithDefault('semanticConfiguration', {})));
    },
    willDestroyElement: function willDestroyElement() {
      this.$()[this.get('moduleName')]('destroy');
      this._super();
    }
  });

});