define('ember-cli-data-validation/validators/max', ['exports', 'ember', 'ember-cli-data-validation/validator', 'ember-cli-data-validation/utils'], function (exports, Ember, Validator, utils) {

	'use strict';

	exports['default'] = Validator['default'].extend({

		/**
	  * Max value for the validator.
	  *
	  * @property max
	  * @type {Number}
	  * @default null
	  */
		max: null,

		validate: function validate(name, value, attribute) {
			var type = attribute.type,
			    maxValue = this.get('max');

			Ember['default'].assert('You must define a `max` for MaxValidator', Ember['default'].isPresent(maxValue));

			if (!utils.hasValue(value)) {
				return;
			}

			var validatorName = 'validate' + Ember['default'].String.classify(type);

			var invalid = true;

			if (Ember['default'].canInvoke(this, validatorName)) {
				invalid = Ember['default'].run(this, validatorName, value, maxValue);
			}

			if (invalid) {
				return this.format(maxValue);
			}
		},

		validateString: function validateString(value, max) {
			if (typeof value !== 'string') {
				return true;
			}

			var length = value && value.length || 0;

			return length > max;
		},

		validateNumber: function validateNumber(value, max) {
			value = parseInt(value, 10);

			if (isNaN(value)) {
				return true;
			}

			return value > max;
		}
	});

});