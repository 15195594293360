define('ember-cli-semantic-ui/components/ui-checkbox', ['exports', 'ember', 'ember-cli-semantic-ui/templates/components/ui-checkbox', 'ember-cli-semantic-ui/mixins/semantic-settings'], function (exports, Ember, layout, SemanticSettings) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(SemanticSettings['default'], {
    classNames: ['ui', 'checkbox'],
    moduleName: 'checkbox',
    init: function init() {
      this.set('inputId', Ember['default'].generateGuid());
      this._super();
    },
    layout: layout['default'],
    actions: {
      check: function check() {
        this.toggleProperty('isChecked');
      }
    }
  });

});