define('ember-cli-data-validation/validators/required', ['exports', 'ember-cli-data-validation/validator', 'ember-cli-data-validation/utils'], function (exports, Validator, utils) {

	'use strict';

	exports['default'] = Validator['default'].extend({
		validate: function validate(name, value) {
			if (!utils.hasValue(value)) {
				return this.format();
			}
		}
	});

});